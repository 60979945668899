import React from 'react'

import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popup from 'react-typeform-embed'

import { StaticImage } from 'gatsby-plugin-image'

const CallToAction = () => {
  return (
    <section>
        
        <Container sx={{mb: 5, minHeight: {xs: '100vh', sm: '500px'}, display: {xs: 'flex', sm: 'flex', md: 'grid'}, flexDirection: {xs: 'column-reverse', sm: 'column-reverse'}, gridTemplateColumns: {md: '1fr 1fr'}}}>
            <Box sx={{ padding: 3, display: { xs: 'none', sm: 'none', md: 'inherit' } }}>
                <StaticImage placeholder={'none'} style={{margin: 'auto'}} loading="eager" src="../../images/CallToAction.svg" alt="Hero image" />
            </Box>
            <Box sx={{ margin: 'auto' }}>
                <Typography color={'primary'} sx={{fontWeight: 700}} variant="h3">Get 20% off your first clean!</Typography>
                <Typography variant="body1">
                Book online in 60 seconds and schedule your home service for as early as today. 
                </Typography>
                <br/>
                <Button sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={() => {typeof window !== "undefined" && window.gtag("event", "generate_lead")}} color="primary" size="large" variant="contained">Book today</Button>
            </Box>
            
        </Container>

    </section>
  )
}

export default CallToAction