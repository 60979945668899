import * as React from "react"

import { Popup } from "react-typeform-embed"

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { StaticImage } from "gatsby-plugin-image"

const ContactSection = () => {
  return (
    <section >
      <Container sx={{mb: 5, minHeight: {xs: '100vh', sm: '500px'}, display: {xs: 'flex', sm: 'flex', md: 'grid'}, flexDirection: {xs: 'column-reverse', sm: 'column-reverse'}, gridTemplateColumns: {md: '1fr 1fr'}}}>
        <Box sx={{ margin: 'auto' }}>
          <Typography color={'primary'} sx={{fontWeight: 700}} variant="h3">Need a hand?</Typography>
          <Typography color={'primary'} sx={{fontWeight: 700}} variant="h3">We got you covered!</Typography>
          <Typography variant="body1">
          We are a reputable, fully insured cleaning service based in State College, PA. Let the experts at Nittany Cleaners remove the burden of cleaning from your to-do list.
          </Typography>
          <br/>
          <Popup style={{width: '100%'}} id="KpSGnihB" size={80}>
            <Button sx={{width: {xs: '100%', sm: 'auto'}}} onClick={() => {typeof window !== "undefined" && window.gtag("event", "generate_lead")}} color="primary" size="large" variant="contained">Get in touch</Button>
          </Popup>
        </Box>
        <Box sx={{ padding: 3 }}>
          <StaticImage placeholder={'none'} style={{margin: 'auto'}} loading="eager" src="../../images/contact.svg" alt="Hero image" />
        </Box>
      </Container>
    </section>
  )
}

export default ContactSection
