import * as React from "react"

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import DateRangeIcon from '@mui/icons-material/DateRange'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'

import { StaticImage } from "gatsby-plugin-image"

const subSections = [
  {title: "One-time Cleaning", icon: <AutoAwesomeIcon color={'primary'}/>, text: "Arrange for a one-time clean whether you're expecting visitors, throwing a party, or preparing their home for sale or rental."},
  {title: "Recurring Cleaning", icon: <DateRangeIcon color={'primary'}/>, text: "We provide weekly, biweekly, and monthly services, as well as custom schedules to tailor a program to your specific needs."},
  {title: "Move-in / Move-out Cleaning", icon: <MeetingRoomIcon color={'primary'}/>, text: "Relocating may be a difficult experience with the burden of pre or post move cleaning. Our experts can ensure that your house is immaculate and gleaming."}
]

const ServicesSection = () => {
  return (
    <section>
      <Container sx={{mb: 5, minHeight: {xs: '100vh', sm: '500px'}}}>
        <Box justifyContent={'center'} display="grid">
          <Typography textAlign={'center'}  color={'primary'} sx={{fontWeight: 700}} variant="h3">Come home to a clean house</Typography>
          <br/>
          <Typography maxWidth={600} variant="body1" textAlign={'center'}>
          We are a reputable, fully insured cleaning service based in State College, PA. Let the experts at Nittany Cleaners remove the burden of cleaning from your to-do list.
          </Typography>
          
        </Box>
        <br/>
        <br/>
          <Box display={"grid"} sx={{gridAutoFlow: {xs: 'row', md: 'column'}}} gap={3}>
            {subSections.map((section, index) => {
              return (
                <Card elevation={3} key={index}>
                  <CardContent>
                    { section.icon }
                    <Typography sx={{fontWeight: 700}} color={"primary"} variant="h5" component="h2">{section.title}</Typography>
                    <Typography mt={1} variant="body1" component="p">{section.text}</Typography>
                  </CardContent>
                </Card>
              )
            })}
          </Box>
      </Container>
    </section>
  )
}

export default ServicesSection
