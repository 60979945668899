import * as React from "react"

import { ThemeProvider } from "@mui/material/styles";

import HeroSection from '../sections/HeroSection'
import ServicesSection from "../sections/ServicesSection";
import HomeCleaningSection from "../sections/HomeCleaningSection";
import MoveOutCleaningSection from "../sections/MoveOutCleaningSection";
import ContactSection from "../sections/ContactSection";
import Navbar from "../components/Navbar";
import Seo from '../components/Seo';
import Footer from "../components/Footer";

import theme from '../theme'
import CallToAction from "../sections/CallToAction";
import Features from "../sections/Features";

const IndexPage = () => {
  return (
    
    <ThemeProvider theme={theme}>
      <Seo title="Home"/>
      <main>
        <title>Home Page</title>
        <Navbar/>
        <HeroSection />
        <ServicesSection/>
        <CallToAction/>
        <Features/>
        {/* <HomeCleaningSection/> */}
        {/* <MoveOutCleaningSection/> */}
        <ContactSection/>
        {/* <Widget id="KpSGnihB" height={500}/> */}
        <Footer/>
      </main>
    </ThemeProvider>
  )
}

export default IndexPage
