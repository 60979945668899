import React from 'react'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { StaticImage } from 'gatsby-plugin-image'

const Navbar = () => {
  return (
    <AppBar elevation={0} color="transparent" position="static">
        <Toolbar>
            <StaticImage placeholder={'none'} height={50} src="../../images/Logo.svg" alt="Nittany Cleaners logo"/>
        </Toolbar>
    </AppBar>
  )
}

export default Navbar