import React from 'react'

import Container from '@mui/material/Container'

import { StaticImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const Footer = () => {
  return (
    <footer>
        <Container>
            <Box display={{xs: 'inherit', sm: 'flex'}} justifyContent={"space-between"} mb={2} mt={2}>
                <StaticImage placeholder={'none'} loading="lazy" src="../../images/footer_logo.png" alt="Footer Logo" />
                <Typography>© 2022 Nittany Cleaners LLC. All rights reserved.</Typography>
            </Box>
        </Container>
    </footer>
  )
}

export default Footer