import { createTheme } from '@mui/material/styles';

// export default {
//     colors: {
//       black: '#000e1a',
//       white: '#fff',
//       blue: '#007ce0',
//       navy: '#004175',
//     },
//   }
const theme = createTheme({
  palette: {
    primary: {
      main: '#006780',

    },
    secondary: {
        main: '#D1F4FF',
    }
  },
  typography: {
    fontFamily: 'Outfit'
  },
  shape: {
    borderRadius: 24,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: '3rem',
          paddingRight: '3rem',
          borderRadius: '100px',
          textTransform: 'none'
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#ebfaff'
        },
      },
    }
  }
})
export default theme