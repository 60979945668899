import React from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
// import CardContent from '@mui/material/CardContent'

import { StaticImage } from "gatsby-plugin-image"
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import LayersIcon from '@mui/icons-material/Layers'
import ChairIcon from '@mui/icons-material/Chair'
import CountertopsIcon from '@mui/icons-material/Countertops'
import BathtubIcon from '@mui/icons-material/Bathtub'
import FlatwareIcon from '@mui/icons-material/Flatware'
import KingBedIcon from '@mui/icons-material/KingBed'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService' 

const subSections = [
    {title: 'Floors', icon: <LayersIcon color="primary"/>, text: 'Hardwood and carpets, linoleum, laminate, tile'},
    {title: 'Living Rooms', icon: <ChairIcon color="primary"/>, text: 'Couches, coffee tables, chairs, TVs'},
    {title: 'Kitchens', icon: <CountertopsIcon color="primary"/>, text: 'Ovens, stoves, sinks, counters, microwaves'},
    {title: 'Bedrooms', icon: <KingBedIcon color="primary"/>, text: 'Bed frames, closets, mirrors, desks, windows'},
    {title: 'Bathrooms', icon: <BathtubIcon color="primary"/>, text: 'Bathtubs, showers, toilets, sinks'},
    {title: 'Kids Rooms', icon: <ChildFriendlyIcon color="primary"/>, text: 'Cribs, toys, dressers, desks'},
    {title: 'Dining Rooms', icon: <FlatwareIcon color="primary" />, text: 'Kitchen tables, chairs, chandeliers'},
    {title: 'Laundry Rooms', icon: <LocalLaundryServiceIcon color="primary"/>, text: 'Washers and dryers, floors, shelves'},
  ]

const Features = () => {
  return (
    <section>
      <Container sx={{ mb: 5, minHeight: {xs: '100vh', sm: '500px'},}}>
            <Box justifyContent={'center'} display="grid">
                <Typography textAlign={{xs: 'left', sm: 'center'}}  color={'primary'} sx={{fontWeight: 700}} variant="h3">How we can help</Typography>
                <br/>
                <Typography maxWidth={600} variant="body1" textAlign={{xs: 'left', sm: 'center'}}>
                We are committed to getting your home looking as good as new. You can expect the following areas to be cleaned through our services:
                </Typography>
            </Box>
            <br/>
          <Box maxWidth={800} m="auto" display="grid" gap={2} sx={{ gridTemplateColumns: {xs: '1fr', sm: '1fr 1fr'}}}>
          { subSections.map((subSection, index) => {
            return (
              <Card key={index}>
                {/* <CardContent>
                  <Typography variant="overline">{subSection.title}</Typography>
                  <Typography variant="body1">{subSection.text}</Typography>
                </CardContent> */}
                <ListItem>
                    <ListItemIcon>
                        { subSection.icon }
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={{fontWeight: 700}}>{subSection.title}</Typography>} secondary={subSection.text} />
                </ListItem>
              </Card>
            )
          })
          } 
        </Box>
      </Container>
    </section>
  )
}

export default Features